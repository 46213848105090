import React from "react"
import Fade from "react-reveal/Fade"

import man from "../../images/home-man.webp"


const Testimonial = () => {
  return (
    <div className="max-w-7xl mx-auto px-6 mt-10 h-auto mb-10">
      <Fade bottom>
        <div className="w-full flex flex-row justify-between align-middle bg-lightblack p-12 rounded-lg xxs:flex-col xs:flex-col sm:flex-row xxs:p-5 lg:p-12">
          <div className="w-3/4 xxs:w-full xs:w-full sm:w-3/4">
            <div className="font-poppins text-white font-semibold text-2xl leading-10 xxs:text-xs xxs:font-normal sm:text-2xl">
              "Orangethink has achieved rapid growth in cross-border e-commerce and global novel platforms through innovative technology and excellent project management. Whether it's tech architecture or user experience optimization, our team is always at the forefront, ensuring every project is successfully delivered and yields tangible results."
            </div>
            <div className="mt-10">
              <h1 className="text-white text-3xl font-bold font-poppins">
              Elena Liu
              </h1>
              <h1 className="text-white text-sm font-semibold font-poppins opacity-50">
                Senior Project Manager
              </h1>
            </div>
          </div>
          <div className="w-1/4 overflow-hidden flex align-middle justify-center xxs:hidden sm:block">
            <img
              className="rounded-xl w-auto h-auto"
              src={man}
            ></img>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default Testimonial
