import React from "react"
import Input from "../Atoms/input"
import Button from "../Atoms/button"
import Fade from "react-reveal/Fade"

// Assets
import HeaderImage from "../../images/home-bg.webp"

const Header = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-8">
      <Fade>
        <div>
          <h2 className="text-white font-poppins opacity-50 font-semibold mt-10 sm:mt-20 text-xs sm:text-sm md:text-base lg:text-xl">
            Boundless Ideas, Beyond Borders
          </h2>

          <h1 className="text-white font-poppins font-semibold text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl mt-5">
            Change starts with <br className="hidden sm:block" /> a{" "}
            <span className="text-gradient bg-gradient-to-r from-pink to-purple">
              single idea.
            </span>
          </h1>
        </div>
      </Fade>
      <div className="max-w-7xl mx-auto mt-10">
        <div className="w-full h-auto overflow-hidden rounded-xl">
          <img className="w-full h-auto" src={HeaderImage} alt="Header"></img>
        </div>
      </div>
    </div>
  )
}

export default Header
