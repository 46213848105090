import React from "react"
import Fade from "react-reveal/Fade"

const FeatureSection = () => {
  return (
    <div className="max-w-7xl mx-auto">
      <Fade bottom cascade>
        <div className="mt-10 px-4 sm:px-8">
          <h1 className="text-white font-bold text-2xl sm:text-3xl lg:text-5xl xl:text-7xl">
            We Are <br />
          </h1>
          <h2 className="text-white text-sm mt-5 opacity-50">
            We transform bold concepts into industry-leading solutions. Whether it's our cross-border e-commerce platform or our global novel platform, OrangeThink Group empowers businesses and readers worldwide with cutting-edge technology. Every line of code, every feature is designed to enhance the user experience and drive success.
          </h2>
          <h2 className="text-white text-sm mt-5 opacity-50">
            OrangeThink Group has been pushing the boundaries of innovation, leveraging the power of data and connectivity to create solutions that define the future. Our technology not only drives business growth but also creates seamless digital experiences for users across the globe, helping businesses and creators stay ahead in the fast-evolving web world.
          </h2>
        </div>
      </Fade>

      {/* 卡片布局 */}
      <Fade bottom cascade>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 mt-10 px-4 sm:px-8">
          <div className="rounded-xl h-52 flex flex-col justify-center text-center bg-gradient-to-r from-lightblack to-lightblack hover:from-purple hover:to-pink transition duration-500 ease-in-out">
            <h1 className="text-white text-4xl">25%</h1>
            <h3 className="text-white text-sm">Global User Growth Rate</h3>
          </div>
          <div className="rounded-xl h-52 flex flex-col justify-center text-center bg-gradient-to-r from-lightblack to-lightblack hover:from-purple hover:to-pink transition duration-500 ease-in-out">
            <h1 className="text-white text-4xl">5 million</h1>
            <h3 className="text-white text-sm">Monthly Active Users</h3>
          </div>
          <div className="rounded-xl h-52 flex flex-col justify-center text-center bg-gradient-to-r from-lightblack to-lightblack hover:from-purple hover:to-pink transition duration-500 ease-in-out">
            <h1 className="text-white text-4xl">30%</h1>
            <h3 className="text-white text-sm">Cross-Border Order Transaction Growth Rate</h3>
          </div>
          <div className="rounded-xl h-52 flex flex-col justify-center text-center bg-gradient-to-r from-lightblack to-lightblack hover:from-purple hover:to-pink transition duration-500 ease-in-out">
            <h1 className="text-white text-4xl">20%</h1>
            <h3 className="text-white text-sm">Content Consumption Time Growth Rate</h3>
          </div>
        </div>
      </Fade>

      {/* 另一部分内容 */}
      <Fade bottom cascade>
        <div className="mt-10 px-4 sm:px-8">
          <h1 className="text-white text-2xl sm:text-3xl lg:text-5xl xl:text-7xl font-bold">
            From innovative ideas, to global platforms <br />
          </h1>
          <h2 className="text-white text-sm mt-5 opacity-50">
            We transform bold concepts into industry-leading solutions. Whether it's our cross-border e-commerce platform or our global novel platform, Orangethink empowers businesses and readers worldwide with cutting-edge technology. Every line of code, every feature is designed to enhance the user experience and drive success.
          </h2>
          <h2 className="text-white text-sm mt-5 opacity-50">
            Lorem Ipsum is simply dummy text of the internet and technology industry. Orangethink has been pushing the boundaries of innovation, leveraging the power of data and connectivity to create solutions that define the future.
          </h2>
        </div>
      </Fade>

      {/* 核心行为部分 */}
      <Fade bottom cascade>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-10 px-4 sm:px-8">
          <div className="bg-gradient-to-r from-pink to-purple p-8 rounded-xl">
            <h1 className="text-black font-bold text-2xl">Stats</h1>
            <div className="mt-5 space-y-4">
              <div>
                <h2 className="text-black opacity-50 text-lg">Year Founded</h2>
                <h1 className="text-black text-base">2024</h1>
              </div>
              <div>
                <h2 className="text-black opacity-50 text-lg">Total Funding</h2>
                <h1 className="text-black text-base">$10M</h1>
              </div>
              <div>
                <h2 className="text-black opacity-50 text-lg">Team Members</h2>
                <h1 className="text-black text-base">70</h1>
              </div>
            </div>
          </div>

          <div className="bg-lightblack p-8 rounded-xl">
            <h1 className="text-white text-2xl">Our Core Behaviours</h1>
            <p className="mt-5 text-white opacity-50 text-sm">
              Our core behaviours at Orangethink drive every decision and action we take. Whether in our cross-border e-commerce platform or our global novel platform, every step reflects our commitment to innovation, collaboration, and user-centricity.
              <br />
              These core behaviours not only shape our culture but set clear standards for us in the global internet industry.
            </p>
          </div>
        </div>
      </Fade>

      {/* 最后一部分 */}
      <Fade bottom cascade>
        <div className="mt-10 px-4 sm:px-8">
          <h1 className="text-white text-2xl sm:text-3xl lg:text-5xl xl:text-7xl font-bold">
            Work more easily with everyone
          </h1>
          <h2 className="text-white text-sm mt-5 opacity-50">
            Orangethink is committed to creating a seamless environment for collaboration. Whether it's with partners on cross-border e-commerce projects or global authors on our novel platform, our technology makes communication and teamwork smoother and more efficient.
          </h2>
        </div>
      </Fade>

      <Fade bottom cascade>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-10 px-4 sm:px-8">
          <div className="bg-gradient-to-r from-pink to-purple p-8 rounded-xl">
            <h1 className="text-black font-bold text-2xl">
              Built with speed in mind
            </h1>
            <p className="mt-5 text-black opacity-50 text-sm">
              Every technology and platform at Orangethink is designed with speed and efficiency at its core. Whether it's fast transaction processing on our cross-border e-commerce platform or rapid content loading on our global novel platform, we ensure that users experience the best in the shortest possible time.
            </p>
          </div>

          <div className="bg-lightblack p-8 rounded-xl">
            <h1 className="text-white text-2xl">
              Work From anywhere
            </h1>
            <p className="mt-5 text-white opacity-50 text-sm">
              Orangethink technology enables you to manage your business or create content from anywhere. Our cross-border e-commerce platform and global novel platform offer seamless access on any device.
            </p>
          </div>

          <div className="bg-lightblack p-8 rounded-xl">
            <h1 className="text-white text-2xl">
              Chat and share in one tool
            </h1>
            <p className="mt-5 text-white opacity-50 text-sm">
              At Orangethink, our integrated platform allows you to communicate quickly and share content effortlessly. Whether it's real-time collaboration with partners or engaging with authors and readers on our novel platform.
            </p>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default FeatureSection
